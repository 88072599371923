import { defineComponent, reactive, toRefs } from 'vue';
import { ElPopover } from 'element-plus';
export default defineComponent({
  name: 'MapTool',
  components: {
    ElPopover
  },
  props: {
    onPolygon: {
      type: Function,
      required: false
    },
    onCricle: {
      type: Function,
      required: false
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      state: 1
    });
    function handleDragMap() {}
    async function handlePolygon() {
      if (props.onPolygon) {
        await props.onPolygon();
      }
    }
    async function handleCricle() {
      if (props.onCricle) {
        await props.onCricle();
      }
    }
    async function handleClean() {
      emit('handleClean');
    }
    return {
      ...toRefs(refData),
      handlePolygon,
      handleCricle,
      handleClean
    };
  }
});