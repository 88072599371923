import { defineComponent, reactive, toRefs } from 'vue';
import { useDebounce } from '@/hooks/core/useDebounce';
import { ElAutocomplete } from 'element-plus';
export default defineComponent({
  name: 'MapSearchInput',
  props: {
    refMap: {
      type: Object
    }
  },
  components: {
    ElAutocomplete
  },
  setup(props) {
    const refData = reactive({
      searchStr: '',
      mapRef: null,
      local: null,
      total: [],
      list: [],
      loading: false,
      marker: null
    });
    function querySearch() {}
    const [debounceOnSearch] = useDebounce(querySearch, 500);
    //初始化方法
    const init = () => {
      const map = new BMap.Map(refData.mapRef);
      refData.list = [];
      const ac = new BMap.Autocomplete({
        input: 'searchInput',
        location: map
      }); //建立一个自动完成的对象
      ac.show();
      ac.addEventListener('onconfirm', function (e) {
        var _value = e.item.value;
        const myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
        setPlace(myValue);
      });
      function setPlace(Value) {
        map.clearOverlays(); //清除地图上所有覆盖物
        function myFun() {
          var pp = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
          handleChange(pp.lng, pp.lat);
        }
        var local = new BMap.LocalSearch(map, {
          //智能搜索
          onSearchComplete: myFun
        });
        local.search(Value);
      }
    };
    const remoteMethod = query => {
      refData.loading = true;
      if (query !== '') {
        setTimeout(() => {
          refData.local.search(query);
        }, 200);
      } else {
        refData.list = [];
      }
    };
    //地址中心点设置
    const handleChange = (lon, lat) => {
      const map = props.refMap.getMap();
      refData.marker && map.removeCover(refData.marker);
      map.setCenter([lon, lat]);
      map.setZoom(16);
      refData.marker = map.addMarker([lon, lat], {});
      map.setMap(refData.marker);
    };
    setTimeout(() => {
      init();
    }, 2000);
    return {
      ...toRefs(refData),
      debounceOnSearch,
      remoteMethod,
      handleChange
    };
  }
});