import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3126092e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "map_wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BSJMap = _resolveComponent("BSJMap");
  const _component_MapTool = _resolveComponent("MapTool");
  const _component_SearchTool = _resolveComponent("SearchTool");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BSJMap, {
    ref: "refMap",
    center: _ctx.mapCenter,
    zoom: _ctx.mapZoom
  }, null, 8, ["center", "zoom"]), _createVNode(_component_MapTool, {
    class: "tool_box",
    onCricle: _ctx.drawCircle,
    onPolygon: _ctx.drawPolygon,
    onHandleClean: _ctx.onClearMap
  }, null, 8, ["onCricle", "onPolygon", "onHandleClean"]), _createVNode(_component_SearchTool, {
    refMap: _ctx.refMap,
    class: "search_box"
  }, null, 8, ["refMap"])]);
}