import { defineComponent, reactive, toRefs } from 'vue';
import BSJMap from '@/components/BSJMap';
import MapTool from './FenceMapTool.vue';
import SearchTool from './FenceMapSearchInput.vue';
import { isNullOrUnDef } from '@/utils/is';
import { bdDecrypt, bdEncrypt } from '@/utils/bdGPS';
export default defineComponent({
  name: 'SafetyFenceMap',
  components: {
    BSJMap,
    MapTool,
    SearchTool
  },
  emits: ['drawFenceOk', 'clearMap'],
  props: {
    mapZoom: {
      type: Number,
      default: 12
    },
    mapCenter: {
      type: Object,
      default: null
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      refMap: null,
      dialogVisible: false
    });
    // 围栏圆形实例
    let fenceRound = null;
    function getMap() {
      return [refData.refMap.getMap(), refData.refMap];
    }
    /** 画圆形 */
    async function drawCircle() {
      const [, refMap] = getMap();
      if (isNullOrUnDef(refMap)) return;
      try {
        clearMap();
        const result = await refMap.drawCircle();
        if (isNullOrUnDef(result.path)) return;
        const center = result.center;
        const centerPoint = bdDecrypt([center.lng, center.lat]);
        const pointList = result.path.map(({
          lat,
          lng
        }) => bdDecrypt({
          lat,
          lng
        }));
        emit('drawFenceOk', {
          type: 1,
          pointList,
          radius: result.radius,
          centerLat: centerPoint.lat,
          centerLon: centerPoint.lon
        });
      } catch (error) {
        console.error(error);
      }
    }
    /** 画多边形 */
    async function drawPolygon() {
      const [map, refMap] = getMap();
      if (isNullOrUnDef(refMap)) return;
      try {
        clearMap();
        const result = await refMap.drawPolygon();
        if (isNullOrUnDef(result.path)) return;
        const pointList = result.path.map(({
          lat,
          lng
        }) => bdDecrypt({
          lat,
          lng
        }));
        emit('drawFenceOk', {
          type: 2,
          pointList
        });
      } catch (error) {
        console.error(error);
      }
    }
    /** 通过已有经纬度 绘制多边形 */
    function renderPolygon(path) {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      clearMap();
      if (path.length === 0) return;
      const newPath = path.map(({
        lon,
        lat
      }) => {
        const point = bdEncrypt({
          lat,
          lon
        });
        return [point.lon, point.lat];
      });
      map.setPolygon(newPath, {
        strokeColor: '#409EFF',
        strokeWeight: 3,
        strokeOpacity: 1
      });
      map.setCenter(newPath[0]);
    }
    /** 通过经纬度 绘制圆形 */
    /** 通过已有经纬度 绘制圆形 围栏 */
    function renderCircle(path, radius) {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      clearMap();
      if (!path) return;
      const newPath = bdEncrypt({
        lat: path.lat,
        lon: path.lon
      });
      // 没有半径
      map.setCircle([newPath.lon, newPath.lat], radius, {
        strokeColor: '#409EFF',
        strokeWeight: 3,
        strokeOpacity: 1
      });
      map.setCenter([newPath.lon, newPath.lat]);
    }
    function setMapZoom(zoom) {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      map.setZoom(zoom);
    }
    function clearMap() {
      var _map$instance;
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      (_map$instance = map.instance) === null || _map$instance === void 0 || _map$instance.clearOverlays();
    }
    function onClearMap() {
      clearMap();
      emit('clearMap');
    }
    return {
      ...toRefs(refData),
      drawCircle,
      drawPolygon,
      renderPolygon,
      renderCircle,
      clearMap,
      getMap,
      onClearMap,
      setMapZoom
    };
  }
});